import PriceStructure from "@/model/PriceStructure";
import TimelineEvent from "@/model/TimelineEvent";
export default class Product {
    id;
    title;
    subtitle;
    shortDescription;
    description;
    image;
    productMetaTypes = [];
    bookingDurationType;
    bookingIntervalLength = 1;
    priceStructure = new PriceStructure();
    resourceGroups = [];
    resources = [];
    startLocation;
    endLocation;
    timelineEvents = [];
    crossSells = [];
    init(other) {
        // Temp copy and reassign because otherwise setting this.timelineEvents.length to 0 will also clear the other.timelineEvents
        const tempCopy = { ...this };
        Object.assign(this, other);
        this.timelineEvents = tempCopy.timelineEvents;
        this.priceStructure = new PriceStructure();
        this.priceStructure.init(other.priceStructure);
        this.timelineEvents.length = 0;
        other.timelineEvents.forEach(value => {
            const timelineEvent = new TimelineEvent();
            timelineEvent.assign(value);
            this.timelineEvents.push(timelineEvent);
        });
    }
    /**
     * Returns a list of resources that are assigned to this product, either via resource
     * groups or individual resources.
     */
    getAssignedResources() {
        const assignedResources = [];
        assignedResources.push(...this.resources);
        this.resourceGroups.forEach(resourceGroup => {
            resourceGroup.resources.forEach(resource => {
                if (assignedResources.find(r => r.id === resource.id) === undefined) {
                    assignedResources.push(resource);
                }
            });
        });
        return assignedResources;
    }
}
