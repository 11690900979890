export default class ResourceReservation {
    id;
    orderId;
    orderItemId;
    resourceDto;
    date;
    reservedQuantity;
    constructor(other) {
        if (other) {
            this.assign(other);
        }
    }
    assign(other) {
        Object.assign(this, other);
    }
}
