import Product from "@/model/Product";
import axios from "axios";
export default class ProductService {
    loadProduct(id) {
        return axios.get('product', {
            params: {
                id: id
            },
            handledHttpErrorStatus: [404]
        }).then(response => {
            const responseProduct = new Product();
            responseProduct.init(response.data);
            return responseProduct;
        });
    }
    loadAllProducts() {
        return axios.get('product/all').then(response => {
            const products = [];
            for (const entry of response.data) {
                const product = new Product();
                product.init(entry);
                products.push(product);
            }
            return products;
        });
    }
    createProduct(product) {
        return axios.post("/product/create", product, {
            withCredentials: true
        });
    }
    updateProduct(product) {
        return axios.post("/product/update", product, {
            withCredentials: true
        });
    }
    deleteProduct(productId) {
        return axios.delete("/product/" + productId, {
            withCredentials: true
        });
    }
    saveTimelineEvent(productId, event) {
        return axios.post("/product/" + productId + "/timelineEvent", event, {
            withCredentials: true
        });
    }
    deleteTimelineEvent(eventId) {
        return axios.delete("/product/timelineEvent/" + eventId, {
            withCredentials: true
        });
    }
}
