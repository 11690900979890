import { MapDeserializer } from "@/util/MapDeserializer";
export default class OrderItemTotal {
    grossPrices = new Map();
    grossDiscounts = {};
    grossTotal = 0;
    assign(other) {
        Object.assign(this, other);
        if (!(other.grossPrices instanceof Map)) {
            this.grossPrices = MapDeserializer.deserializePriceAmountMap(other.grossPrices);
        }
    }
}
