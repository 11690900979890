import Price from "@/model/Price.model";
import { LocalTime } from "@js-joda/core";
export class MapDeserializer {
    static deserializePriceTypeMap(prices) {
        const pricesMap = new Map();
        for (const priceType in prices) {
            const priceTypeMap = new Map();
            for (const [referenceId, priceObj] of Object.entries(prices[priceType])) {
                const price = new Price();
                Object.assign(price, priceObj);
                priceTypeMap.set(referenceId == "" ? null : Number(referenceId), price);
            }
            pricesMap.set(priceType, priceTypeMap);
        }
        return pricesMap;
    }
    static deserializePriceAmountMap(record) {
        const pricesMap = new Map();
        for (const priceType in record) {
            const priceAmountMap = new Map();
            for (const [referenceId, priceAmount] of Object.entries(record[priceType])) {
                priceAmountMap.set(referenceId == "" ? null : Number(referenceId), priceAmount);
            }
            pricesMap.set(priceType, priceAmountMap);
        }
        return pricesMap;
    }
    static mapToObject(map) {
        const obj = {};
        for (const [key, value] of map.entries()) {
            const newKey = key === null ? "" : key;
            obj[newKey] = value instanceof Map ? MapDeserializer.mapToObject(value) : value;
        }
        return obj;
    }
    static transformMapsToPlain(data) {
        if (data instanceof Map) {
            return MapDeserializer.mapToObject(data);
        }
        // Array
        if (Array.isArray(data)) {
            return data.map(MapDeserializer.transformMapsToPlain);
        }
        if (data instanceof LocalTime) {
            return data;
        }
        if (Object.prototype.toString.call(data) === '[object Object]') {
            return Object.fromEntries(Object.entries(data).map(([key, val]) => {
                return [key, MapDeserializer.transformMapsToPlain(val)];
            }));
        }
        return data;
    }
}
