import Customer from "@/model/Customer.model";
import OrderItem from "@/model/OrderItem.model";
import { OrderStatus } from "@/model/enum/OrderStatus";
import Totals from "@/model/Totals";
import Contract from "@/model/Contract";
export default class Order {
    id;
    customer = new Customer();
    orderItems = [];
    paymentMethod;
    orderDatetime;
    orderStatus = OrderStatus.NEW;
    customerRemarks;
    internalRemarks;
    contract;
    totals = new Totals();
    assign(other) {
        Object.assign(this, other);
        this.customer = new Customer();
        this.customer.init(other.customer);
        if (other.contract != undefined) {
            this.contract = new Contract();
            this.contract.assign(other.contract);
        }
        this.orderItems = [];
        if (other.orderItems) {
            other.orderItems.forEach(orderItem => {
                const typedOrderItem = new OrderItem();
                typedOrderItem.assign(orderItem);
                this.orderItems.push(typedOrderItem);
            });
        }
        const typedTotals = new Totals();
        typedTotals.assign(other.totals);
        this.totals = typedTotals;
        if (other.orderDatetime) {
            this.orderDatetime = new Date(other.orderDatetime);
        }
    }
}
