export const validationRules = {
    required: (value) => {
        if (Array.isArray(value))
            return value.length > 0 || "Required";
        return !!value || 'Required.';
    },
    email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail.';
    },
    nonNegative: (value) => {
        if (!value)
            return true;
        const stringAsNumber = Number(value);
        let isValid = value.toString().trim() !== '';
        isValid = isValid && !Number.isNaN(stringAsNumber) && stringAsNumber >= 0;
        return isValid || "Bitte einen positiven Wert eingeben";
    },
    nonNegativeInteger: (value) => {
        if (!value)
            return true;
        const stringAsNumber = Number(value);
        let isValid = value.toString().trim() !== '';
        isValid = isValid && !Number.isNaN(stringAsNumber);
        isValid = isValid && Number.isInteger(stringAsNumber) && stringAsNumber >= 0;
        return isValid || "Bitte einen positiven ganzzahligen Wert eingeben";
    },
    min1: (value) => {
        const stringAsNumber = Number(value);
        return stringAsNumber >= 1 || "Minimum 1";
    },
    currency: (value) => {
        if (!value)
            return true;
        let pattern = /,/;
        if (pattern.test(value)) {
            return "Punkt als Seperator verwenden.";
        }
        pattern = /^(-?[0-9]*\.?[0-9]{0,2})$/;
        return pattern.test(value) || 'Nur Zahlen und Punkte erlaubt';
    },
    maxLength(maxLength) {
        return (value) => {
            if (!value) {
                return true;
            }
            return value.length <= maxLength || "Maximal " + maxLength + " Zeichen";
        };
    },
    phoneNumber(value) {
        if (!value)
            return true;
        const pattern = /^[0-9+\-() ]*$/;
        return pattern.test(value) || 'Ungültiges Format';
    },
    completeUrl(value) {
        if (!value)
            return true;
        const pattern = /((http|https):\/\/)/;
        return pattern.test(value) || 'Link mit http:// oder https:// angeben';
    },
    fileTypePdf(value) {
        if (!value || value.length == 0)
            return true;
        return value[0].type == 'application/pdf' || 'Nur PDFs sind erlaubt.';
    },
    maxSum(otherValue, maxSum) {
        return (value) => {
            if (!value)
                return true;
            return Number(value) + Number(otherValue) <= maxSum || 'Es sind maximal noch ' + maxSum + ' Personen (Erwachsene + Kinder) verfügbar';
        };
    },
    decimalPercent(value) {
        if (!value)
            return true;
        const pattern = /^0?.[0-9]+$/;
        return pattern.test(value) || 'Ungültiges Format';
    }
};
