import OrderItemTotal from "@/model/OrderItemTotal";
export default class OrderItem {
    id;
    productId;
    productTitle;
    productStartLocationName;
    productEndLocationName;
    dateFrom = new Date();
    dateTo = new Date();
    bookingMeta = {};
    itemTotal = new OrderItemTotal();
    reservations = {};
    resources = {};
    assign(other) {
        Object.assign(this, other);
        this.dateFrom = new Date(other.dateFrom);
        this.dateTo = new Date(other.dateTo);
        this.bookingMeta = {};
        for (const key of Object.keys(other.bookingMeta)) {
            this.bookingMeta[key] = other.bookingMeta[key];
        }
        this.itemTotal = new OrderItemTotal();
        this.itemTotal.assign(other.itemTotal);
    }
}
