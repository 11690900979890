import { DayOfWeek } from "@js-joda/core";
import { PricingByType } from "@/model/enum/PricingByType";
import { MapDeserializer } from "@/util/MapDeserializer";
export default class PriceStructure {
    id;
    prices = new Map();
    pricingByTypes = [];
    pricedPerNight = false;
    displayPrice;
    discounts = {};
    taxRate;
    isPricingByAmount() {
        return this.pricingByTypes.includes(PricingByType.BY_AMOUNT);
    }
    isPricingByPerson() {
        return this.pricingByTypes.includes(PricingByType.BY_PERSON);
    }
    isPricingByResources() {
        return this.pricingByTypes.includes(PricingByType.BY_RESOURCE);
    }
    init(other) {
        Object.assign(this, other);
        if (!(other instanceof PriceStructure)) {
            this.prices = new Map();
            const otherPrices = other.prices;
            for (const dayOfWeekName in otherPrices) {
                const dayOfWeekPrices = MapDeserializer.deserializePriceTypeMap(otherPrices[dayOfWeekName]);
                this.prices.set(DayOfWeek.valueOf(dayOfWeekName), dayOfWeekPrices);
            }
        }
    }
}
