import Product from "@/model/Product";
import { BookingDurationTypeModel } from "@/model/enum/BookingDurationType.model";
import { ProductMetaTypeModel } from "@/model/enum/ProductMetaType.model";
import { MapDeserializer } from "@/util/MapDeserializer";
export class CartItem {
    product;
    dateFrom = new Date();
    dateTo = new Date();
    bookingMeta = {};
    resourceReservations = {};
    prices = new Map();
    discounts = {};
    constructor(product) {
        this.product = product;
        this.initWithProduct(product);
    }
    initWithProduct(product) {
        this.product = product;
        this.bookingMeta["adults"] = product.productMetaTypes.includes(ProductMetaTypeModel.PERSONEN) ? 1 : 0;
        this.bookingMeta["children"] = 0;
        this.bookingMeta["quantity"] = 0;
        if (product.bookingDurationType == BookingDurationTypeModel.FIXED) {
            this.dateTo.setDate(this.dateFrom.getDate() + product.bookingIntervalLength - 1);
        }
        else if (product.bookingDurationType == BookingDurationTypeModel.VARIABLE && product.priceStructure.pricedPerNight) {
            this.dateTo.setDate(this.dateFrom.getDate() + 1);
        }
    }
    assign(other) {
        Object.assign(this, other);
        this.dateFrom = new Date(other.dateFrom);
        this.dateTo = new Date(other.dateTo);
        this.product = new Product();
        this.product.init(other.product);
        this.prices = MapDeserializer.deserializePriceAmountMap(other.prices);
        return this;
    }
    getResourceTitle(resourceId) {
        let title = this.product.resources.find(resource => resource.id == resourceId)?.name;
        if (!title) {
            title = this.product.resourceGroups.flatMap(resourceGroup => resourceGroup.resources)
                .find(resource => resource.id == resourceId)?.name;
        }
        return title ? title : "";
    }
}
